#myCommitteeSection, #myCommunitySection, #myNoticeSection, #myMajorSection{
    margin-top : 5%;
    // margin : auto;
    margin-left : 5%;
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    width : 90%;
    height : 60vh;
    border-top : 4px solid #7B8A78;   
}
.moreCommitteeSentence, .moreCommunitySentence, .moreMajorSentence, .moreNoticeSentence{
    &:hover{
        cursor : pointer;
        opacity : 80%;
    }
}
#searchSubmitBtn{
    &:hover{
        cursor : pointer;
    }
}
@media (max-width : 768px){
    .searchjsForm{
        width : 60% !important;
    }
    .classificationSelect{
        margin-left : 10% !important;
        font-size : 1px;
    }
    .criterionSelect{
        font-size : 1px;
    }
    .searchEverything{
        margin-left : -3% !important; 
    }
    #myCommunitySection, #myNoticeSection, #myMajorSection, #myCommitteeSection{
        height : 27vh !important;
    }
    .moreNoticeSentence, .moreMajorSentence, .moreCommitteeSentence{
        margin-left : 75% !important;
    }
    .studentsCouncilDate, .noticeDate, .majorDate, .committeeDate, .communityDate{
        width : 20% !important;
    }
}