@media(max-width : 768px){
    .passwordFindingImg{
        max-width : 20% !important;
        margin-left : 10% !important;
    }
    .rebuildPassword{
        display : flex;
        justify-content : center;
        align-items : center;
        margin-left : 0% !important;
        margin-top : 3vh !important;
    }
    .pwFindingMailText{
        margin-left : 20% !important;
    }
    .pwFindingMailInput{
        width : 60% !important;
    }
    .pwRebuildBtn{
        width : 50% !important;
        margin-left : 25% !important;
        font-size : 6px;
    }
}