.innerContainer{
    font-size : 130%;
    display : flex;
    justify-content : space-between;
    align-items : center;
    width : 40%;
    margin-top : 3%;
    .userInfoText{
        width : 59%;
    }
    input{
        height : 33px;
        width : 340px;
    }   
}
#myCommitteeSection, #myCommunitySection{
    margin-top : 5%;
    // margin : auto;
    margin-left : 5%;
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    width : 90%;
    height : 60vh;
    border-top : 4px solid #7B8A78;   
}
.moreCommitteeSentence, .moreCommunitySentence{
    &:hover{
        cursor : pointer;
        opacity : 80%;
    }
}

@media (max-width : 768px){
    .innerContainer{
        width : 95%;
        input{
            width : 70%;
        }
        .userInfoText{
            width : 70%;
        }
    }
    .committeeDate, .communityDate{
        width : 25% !important; 
    }
    .moreCommitteeSentence, .moreCommunitySentence{
        margin-left : 75% !important;
    }
}