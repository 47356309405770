.mainContent {
  // margin-top: 99px;
  font-family: "YeolrinGothic-Light";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea:focus {
  outline: none;
}

@font-face {
  font-family: "YeolrinGothic-Bold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Bold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "YeolrinGothic-Medium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Medium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "YeolrinGothic-Light";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Light.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

.post-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  margin: 6% 0%;
  padding: 0% 6% 0 6%;
  width: 100%;
  font-family: "YeolrinGothic-Light";

  .post-menu {
    width: 20%;
    .post-menu-item {
      margin-bottom: 24px;
      font-family: "YeolrinGothic-Medium";
      font-size: 16px;
    }
  }

  .post-contents-wrap {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .post-name {
      padding-bottom: 23px;
      border-bottom: 5px solid #566b64;
      font-family: "YeolrinGothic-Medium";
      font-size: 28px;
      margin-bottom: 23px;
    }
    .post-contents {
      font-family: "YeolrinGothic-Light";

      .post-list {
        width: 100%;
        .post-list-head {
          display: flex;
          flex-direction: row;
          font-size: 20px;
          font-weight: bold;
          padding-bottom: 23px;
          border-bottom: 3px solid #1d333e;
          .post-list-title {
            text-align: center;
            width: 60%;
          }
          .post-list-createdAt {
            width: 20%;
          }
          .post-list-author {
            width: 10%;
          }
          .post-list-comments {
            width: 10%;
          }
        }
        .post-list-main {
          .post-list-main-element {
            display: flex;
            flex-direction: row;
            padding: 8px 0;
            border-bottom: 1px solid black;
            .post-list-main-title {
              width: 55%;
            }
            .post-list-main-createdAt {
              width: 20%;
            }
            .post-list-main-author {
              width: 10%;
              padding-left: 2.5vw;
              display: flex;
              justify-content: center;
            }
            .post-list-main-commentCount {
              width: 16%;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.active {
  font-family: "YeolrinGothic-Bold";
  color: red;
}
.post-list-main {
  &:hover {
    cursor: pointer;
  }
}
#title-banner {
  margin-top: 200px;
}
@media (max-width: 768px) {
  main {
    // .mainContent{

    .post-wrap {
      .post-contents-wrap {
        width: 80%;
        .post-name {
          font-size: 10px;
        }
        .post-list {
          .post-list-head {
            font-size: 2vw;
            .post-list-title {
              font-size: 10px;
              width: 45% !important;
            }
            .post-list-createdAt {
              font-size: 10px;
              width: 25% !important;
            }
            .post-list-author {
              font-size: 10px;
              width: 15% !important;
            }
            .post-list-comments {
              font-size: 10px;
              width: 15% !important;
              padding-left: 8px;
            }
          }
          .post-list-main {
            font-size: 10px;
            .post-list-main-title {
              width: 30% !important;
              padding-left: 4px !important;
            }
            .post-list-main-createdAt {
              width: 30% !important;
            }
            .post-list-main-author {
              width: 20% !important;
            }
          }
          .baseNav {
            flex-direction: column;
            .post-list-pagination {
              margin-right: 0 !important;
              flex-direction: row;
              justify-content: center !important;
            }
          }
        }
      }
      .post-menu {
        width: 20%;
        .post-menu-item {
          font-size: 10px;
        }
      }
      // }
    }
  }
  .search-wrapper {
    justify-content: center;
  }
  .searchForm {
    width: 60vw !important;
    input {
      width: 70% !important;
    }
  }
}
