.post-list-author {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .post-list-author-img {
        display: flex;
        flex-direction: row;
        padding-right: 0%;
    }
}

.post-list-title-market{
    width :52% !important;
}

.post-list-createdAt-market{
    width :20% !important;
}

.post-list-author-market{
    width: 10% !important
}
#market-wrapper-mobile{
    display : none;
}

.post-list-comments-market{
    width :18% !important;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.post-list-comments{
    // display:flex;
    // align-items: center;
.post-list-commentCount-img{
    width:50%;
    display:flex;
    justify-content: flex-end;
    align-items: center;
}}

.post-list-head-market {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.post-list-main {
    .post-list-main-element {
        display: flex;
        flex-direction: row;
        padding: 8px 0;
        border-bottom: 1px solid black;

        .post-list-market-type {
            width: 5%;
        }
        .post-list-main-market-title {
            width: 45%;
            
        }
        .post-list-main-createdAt {
            width: 22%;
        }
        .post-list-main-author {
            width: 20%;
        }
    }
}

.post-list-main-author-market{
    width:11% !important;
    padding-left : 0px !important;
}

.post-gallery-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .market-gallery-element {
        display: flex;
        flex-direction: column;
        padding: 8px 0;
        border-bottom: 1px solid black;
        width: 24%;
        margin-right: 1%;

        .market-gallery-element-photo-section {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding-bottom: 100%;
            margin-bottom: 8px;
        }

        .market-gallery-element-text-section {
            display: flex;
            flex-direction: row;
            .post-list-market-type {
            }
            .post-list-main-market-title {
            }
        }
    }
}

@media (max-width : 768px){
    .post-wrap{
        .post-contents-wrap{
            .post-contents{
                .post-list{
                    .post-list-head{
                        padding-bottom : 11px;
                        .post-list-title-market{
                            width : 40% !important;
                        }
                        .post-list-createdAt-market{
                            display : flex;
                            justify-content : center;
                        }
                        .post-list-author-market{
                            padding-left : 7% !important;
                            width : 20% !important;
                        }
                        // .post-list-create
                    }
                    .post-list-main{
                        .post-list-main-element{
                            .post-list-market-type{
                                padding-left : 0px !important;
                                width : 15%;
                            }
                            
                        
                        }
                    }
                }
            }
        }
    }
    
    .post-list-author-text{
        display : none;
    }
    .post-list-author-img{
        padding-left : 50%;
    }
    .post-list-market-type{
        width : 8%;
    }
    #market-wrapper-mobile{
        display : flex;
        justify-content : space-between;
    }
    #market-wrapper-computer{
        display : none;
    }
    // .market-computer-image{
    //     display : none !important;
    // }
    #market-mobile-null{
        display : none;
    }
    #market-title-mobile{
        padding-left : 0px !important;
        width : 30%;
    }
    #market-createdAt-mobile{
        padding-left : 0px !important;

    }
    #market-author-mobile{
        // padding-left : 0px !important;
        width : 20% important;

    }
    #market-comments-mobile{
        width : 16% !important; 
        display : flex;
        justify-content : center;
    }
    #post-list-main-created-market{
        padding-left : 0px !important;
    }
}