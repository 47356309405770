@font-face {
    font-family: "YeolrinGothic-Bold";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Bold.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "YeolrinGothic-Medium";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Medium.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "YeolrinGothic-Light";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Light.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
}

.create-post-title {
    width: 100%;
    .create-post-title-input {
        width: 100%;
        padding: 1% 1%;
        font-family: "YeolrinGothic-Light";
        font-size: 15px;
    }

    margin-top: 1%;
    margin-bottom: 3%;
}

.create-post-content {
    width: 100%;
    height: 60vh;
    .create-post-content-input {
        width: 100%;
        height: 100%;
        padding: 1% 1%;
        font-family: "YeolrinGothic-Light";
        font-size: 15px;
    }
    margin-bottom: 3%;
}

.create-post-uploads {
    margin-bottom: 3%;

    .upload-name {
        display: inline-block;
        height: 30px;
        padding: 0 10px;
        vertical-align: middle;
        border: 1px solid #dddddd;
        width: 83%;
        color: #999999;
    }

    label {
        display: inline-block;
        padding: 5px 0px;
        // padding: auto auto;
        color: #fff;
        vertical-align: middle;
        background-color: #999999;
        cursor: pointer;
        height: 30px;
        margin-left: 5%;
        width: 10%;
        text-align: center;
        font-family: "YeolrinGothic-Light";
        font-size: 15px;
    }

    input[type="file"] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        border: 0;
    }
}

.create-post-submit {
    margin-bottom: 3%;
}

.create-post-photo-section {
    width: 100%;
    margin-bottom: 3%;

    .create-post-photo-section-photo-box {
        width: 60%;
        height: fit-content;

        .create-post-photo-section-real-photo {
            width: 100%;
            height: 100%;
        }
    }
}
@media (max-width : 768px) {
    .create-post-uploads{
        .upload-name{
            width : 65%; 
            margin-right : 10%;
        }
        label{
            padding : 8px 0px;
            width : 25%;
            font-size : 1px;
            margin-left : 0%;   
        }
    }
}
