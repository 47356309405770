* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

input:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea:focus {
  outline: none;
}

@font-face {
  font-family: "YeolrinGothic-Bold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Bold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "YeolrinGothic-Medium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Medium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "YeolrinGothic-Light";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Light.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  /* Colors: */
  --unnamed-color-000000: #000000;
  --unnamed-color-1d333e: #1d333e;
  --unnamed-color-ffffff: #ffffff;
  --unnamed-color-707070: #707070;
  --unnamed-color-f5f5f5: #f5f5f5;
  --unnamed-color-1d283e: #1d283e;
  --unnamed-color-fafafa: #fafafa; /* Font/text values */
  --unnamed-font-family-yeolringothic: YeolrinGothic;
  --unnamed-font-family-nanumgothic: NanumGothic;
  --unnamed-font-family-segoe-ui: Segoe UI;
  --unnamed-font-family-pt-sans-caption: PT Sans Caption;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-300: 300px;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-9: 9px;
  --unnamed-font-size-10: 10px;
  --unnamed-font-size-11: 11px;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-13: 13px;
  --unnamed-font-size-15: 15px;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-100: 100px;
  --unnamed-font-size-27-5: 27.5px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-10: 10px;
  --unnamed-line-spacing-14: 14px;
  --unnamed-line-spacing-15: 15px;
  --unnamed-line-spacing-16: 16px;
  --unnamed-line-spacing-17: 17px;
  --unnamed-line-spacing-20: 20px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-26: 26px;
  --unnamed-line-spacing-37: 37px;
  --unnamed-line-spacing-133: 133px;
}

footer .footer-contents-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
  width: 100%;
  padding: 5% 10%;
  background: #e9e9d0;
}

footer .footer-contents-wrap .footer-contents {
  text-align: center;
}

footer .footer-contents-wrap .footer-contents-title {
  margin-bottom: 55px;
  display: flex;
  justify-content: center; /* Layout Properties */
  width: 150px;
  height: 18px; /* UI Properties */
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    14px/19px var(--unnamed-font-family-yeolringothic);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-1d333e);
  text-align: left;
  font: normal normal bold 16px YeolrinGothic;
  letter-spacing: 0px;
  color: #1d333e;
  opacity: 1;
}

footer .footer-contents-wrap .footer-contents-item {
  font-size: 22px;
  margin-bottom: 31px; /* Layout Properties */
  width: 150px;
  height: 16px; /* UI Properties */
  text-align: center;
  font: normal normal 400 14px YeolrinGothic;
  letter-spacing: 0px;
  color: #1d333e;
  opacity: 1;
}

footer .footer-belt {
  height: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 14px;
  font-family: "YeolrinGothic-Light";
  color: #e9e9d0;
  background: #566b64;
}

footer .footer-belt span {
  margin: 0 22px;
}

/*# sourceMappingURL=base.css.map */
.footer-menu {
  &:hover {
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  footer {
    width: 100%;
    font-size: 10px;
    margin-top: 5% !important;
    .footer-contents-wrap {
      width: 100%;
      font-size: 10px;
      padding: 5% 0%;
      .footer-contents {
        .footer-contents-title {
          font-size: 10px;
          width: 20vw;
        }
        .footer-contents-item {
          font-size: 10px;
          width: 20vw;
        }
      }
    }
    .footer-belt {
      width: 100%;
      font-size: 10px;
      line-height: 28px;
      span {
        margin: 0 8px;
      }
    }
  }
}
