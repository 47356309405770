@font-face {
  font-family: "YeolrinGothic-Bold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Bold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "YeolrinGothic-Medium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Medium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "YeolrinGothic-Light";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/YeolrinGothic-Light.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

.homeContent {
  // padding-top: 99px;
  font-family: "YeolrinGothic-Light";

  .imgSection {
    height: 45vh;
    padding-top: 3.5%;
    padding-left: 3.5%;
    z-index: -3;
    // box-shadow: 0px 0.5px 20px -1px inset gray;

    img {
      width: 100%;
      height: 100%;
    }
    .imgSection-title {
      color: white;

      .imgSection-title-1 {
        font-family: "YeolrinGothic-Medium";
        font-size: 30px;
        color: white;
        margin-bottom: 1.5%;
        text-shadow: 3px 0px 20px gray;
      }

      .imgSection-title-2 {
        font-family: "YeolrinGothic-Light";
        font-size: 14px;
        color: white;
      }
    }
  }

  .noticeSection {
    margin-left: 5%;
    height: 50vh;
    margin-top: 5%;
    padding: 5%;
    display: flex;
    flex-direction: row;

    .notices {
      width: 50%;
      height: 280%;
    }
    .homeNotice {
      display: flex;
      width: 80%;
      justify-content: space-between;
    }
    .majorNav {
      width: 50%;
      height: 200%;
    }
    .majorWrapper {
      margin-left: 10%;

      .majorDiv {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  #noticesMore {
    &:hover {
      cursor: pointer;
    }
  }
  .EventSection {
    margin-left: 5%;
    height: 50vh;
    display: flex;
    flex-direction: row;
    .events {
      width: 50%;
    }

    .useMarket {
      width: 50%;
    }
  }
}
.homeEvent,
.homeNotice {
  &:hover {
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  main {
    width: 100%;
    font-size: 10px;
    .homeContent {
      .imgSection {
        height: 14vh;
        background-repeat: no-repeat;
        background-size: 105% !important;
        width: 100%;

        .imgSection-title {
          .imgSection-title-1 {
            font-size: 10px;
          }
        }
      }
      .noticeSection {
        flex-direction: column;
        margin-left: 0%;
        .notices {
          width: 130%;
          padding-top: 2vh;
          justify-content: center;
        }
        .majorNav {
          width: 100% !important;
          .majorWrapper {
            width: 115% !important;
            padding-left: 0% !important;
            margin-left: 0% !important;
          }
        }
      }
      .EventSection {
        margin-top: 55vh !important;
        flex-direction: column;
        margin-bottom: 35vh;
        .events {
          padding: 0% !important;
          width: 100% !important;
          .eventHeader {
            width: 95% !important;
          }
          .homeEvent {
            width: 95% !important;
            div {
              font-size: 10px !important;
            }
          }
          .eventFooter {
            width: 95% !important;
          }
        }
        .usedMarket {
          width: 100% !important;
          margin-left: 0% !important;
          margin-bottom: 3vh;
        }
      }
    }
  }
}
